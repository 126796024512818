/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { adminBalanceEntriesYearGet } from '../fn/operations/admin-balance-entries-year-get';
import { AdminBalanceEntriesYearGet$Params } from '../fn/operations/admin-balance-entries-year-get';
import { adminClimateEntrySTokenDelete } from '../fn/operations/admin-climate-entry-s-token-delete';
import { AdminClimateEntrySTokenDelete$Params } from '../fn/operations/admin-climate-entry-s-token-delete';
import { adminClimateEntrySTokenMultiplierPatch } from '../fn/operations/admin-climate-entry-s-token-multiplier-patch';
import { AdminClimateEntrySTokenMultiplierPatch$Params } from '../fn/operations/admin-climate-entry-s-token-multiplier-patch';
import { adminClimateTemplatePost } from '../fn/operations/admin-climate-template-post';
import { AdminClimateTemplatePost$Params } from '../fn/operations/admin-climate-template-post';
import { adminLoginPost } from '../fn/operations/admin-login-post';
import { AdminLoginPost$Params } from '../fn/operations/admin-login-post';
import { BalanceEntries } from '../models/balance-entries';
import { ClimateCalculationKeys } from '../models/climate-calculation-keys';
import { climateCalculationKeysGet } from '../fn/operations/climate-calculation-keys-get';
import { ClimateCalculationKeysGet$Params } from '../fn/operations/climate-calculation-keys-get';
import { ClimateEntry } from '../models/climate-entry';
import { climateEntryCancelSTokenPost } from '../fn/operations/climate-entry-cancel-s-token-post';
import { ClimateEntryCancelSTokenPost$Params } from '../fn/operations/climate-entry-cancel-s-token-post';
import { climateEntryPut } from '../fn/operations/climate-entry-put';
import { ClimateEntryPut$Params } from '../fn/operations/climate-entry-put';
import { ClimateTemplate } from '../models/climate-template';
import { climateTemplateSTokenGet } from '../fn/operations/climate-template-s-token-get';
import { ClimateTemplateSTokenGet$Params } from '../fn/operations/climate-template-s-token-get';
import { Token } from '../models/token';

@Injectable({ providedIn: 'root' })
export class ApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `adminBalanceEntriesYearGet()` */
  static readonly AdminBalanceEntriesYearGetPath = '/admin/balanceEntries/{year}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminBalanceEntriesYearGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminBalanceEntriesYearGet$Response(params: AdminBalanceEntriesYearGet$Params, context?: HttpContext): Observable<StrictHttpResponse<BalanceEntries>> {
    return adminBalanceEntriesYearGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminBalanceEntriesYearGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminBalanceEntriesYearGet(params: AdminBalanceEntriesYearGet$Params, context?: HttpContext): Observable<BalanceEntries> {
    return this.adminBalanceEntriesYearGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<BalanceEntries>): BalanceEntries => r.body)
    );
  }

  /** Path part for operation `adminClimateEntrySTokenDelete()` */
  static readonly AdminClimateEntrySTokenDeletePath = '/admin/climateEntry/{sToken}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminClimateEntrySTokenDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminClimateEntrySTokenDelete$Response(params: AdminClimateEntrySTokenDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return adminClimateEntrySTokenDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminClimateEntrySTokenDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminClimateEntrySTokenDelete(params: AdminClimateEntrySTokenDelete$Params, context?: HttpContext): Observable<string> {
    return this.adminClimateEntrySTokenDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `adminClimateEntrySTokenMultiplierPatch()` */
  static readonly AdminClimateEntrySTokenMultiplierPatchPath = '/admin/climateEntry/{sToken}/{multiplier}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminClimateEntrySTokenMultiplierPatch()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminClimateEntrySTokenMultiplierPatch$Response(params: AdminClimateEntrySTokenMultiplierPatch$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return adminClimateEntrySTokenMultiplierPatch(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminClimateEntrySTokenMultiplierPatch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminClimateEntrySTokenMultiplierPatch(params: AdminClimateEntrySTokenMultiplierPatch$Params, context?: HttpContext): Observable<{
}> {
    return this.adminClimateEntrySTokenMultiplierPatch$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `adminClimateTemplatePost()` */
  static readonly AdminClimateTemplatePostPath = '/admin/climateTemplate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminClimateTemplatePost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminClimateTemplatePost$Response(params: AdminClimateTemplatePost$Params, context?: HttpContext): Observable<StrictHttpResponse<ClimateEntry>> {
    return adminClimateTemplatePost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminClimateTemplatePost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminClimateTemplatePost(params: AdminClimateTemplatePost$Params, context?: HttpContext): Observable<ClimateEntry> {
    return this.adminClimateTemplatePost$Response(params, context).pipe(
      map((r: StrictHttpResponse<ClimateEntry>): ClimateEntry => r.body)
    );
  }

  /** Path part for operation `adminLoginPost()` */
  static readonly AdminLoginPostPath = '/admin/login';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminLoginPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminLoginPost$Response(params: AdminLoginPost$Params, context?: HttpContext): Observable<StrictHttpResponse<Token>> {
    return adminLoginPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminLoginPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminLoginPost(params: AdminLoginPost$Params, context?: HttpContext): Observable<Token> {
    return this.adminLoginPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<Token>): Token => r.body)
    );
  }

  /** Path part for operation `climateCalculationKeysGet()` */
  static readonly ClimateCalculationKeysGetPath = '/climateCalculationKeys';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `climateCalculationKeysGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  climateCalculationKeysGet$Response(params?: ClimateCalculationKeysGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ClimateCalculationKeys>> {
    return climateCalculationKeysGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `climateCalculationKeysGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  climateCalculationKeysGet(params?: ClimateCalculationKeysGet$Params, context?: HttpContext): Observable<ClimateCalculationKeys> {
    return this.climateCalculationKeysGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ClimateCalculationKeys>): ClimateCalculationKeys => r.body)
    );
  }

  /** Path part for operation `climateEntryPut()` */
  static readonly ClimateEntryPutPath = '/climateEntry';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `climateEntryPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  climateEntryPut$Response(params: ClimateEntryPut$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return climateEntryPut(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `climateEntryPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  climateEntryPut(params: ClimateEntryPut$Params, context?: HttpContext): Observable<string> {
    return this.climateEntryPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `climateEntryCancelSTokenPost()` */
  static readonly ClimateEntryCancelSTokenPostPath = '/climateEntry/cancel/{sToken}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `climateEntryCancelSTokenPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  climateEntryCancelSTokenPost$Response(params: ClimateEntryCancelSTokenPost$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return climateEntryCancelSTokenPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `climateEntryCancelSTokenPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  climateEntryCancelSTokenPost(params: ClimateEntryCancelSTokenPost$Params, context?: HttpContext): Observable<string> {
    return this.climateEntryCancelSTokenPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `climateTemplateSTokenGet()` */
  static readonly ClimateTemplateSTokenGetPath = '/climateTemplate/{sToken}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `climateTemplateSTokenGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  climateTemplateSTokenGet$Response(params: ClimateTemplateSTokenGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ClimateTemplate>> {
    return climateTemplateSTokenGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `climateTemplateSTokenGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  climateTemplateSTokenGet(params: ClimateTemplateSTokenGet$Params, context?: HttpContext): Observable<ClimateTemplate> {
    return this.climateTemplateSTokenGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ClimateTemplate>): ClimateTemplate => r.body)
    );
  }

}
